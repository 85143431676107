'use strict';

var callBound = require('call-bind/callBound');

var hasToStringTag = require('has-tostringtag/shams')();

var has;
var $exec;
var isRegexMarker;
var badStringifier;

if (hasToStringTag) {
  has = callBound('Object.prototype.hasOwnProperty');
  $exec = callBound('RegExp.prototype.exec');
  isRegexMarker = {};

  var throwRegexMarker = function throwRegexMarker() {
    throw isRegexMarker;
  };

  badStringifier = {
    toString: throwRegexMarker,
    valueOf: throwRegexMarker
  };

  if (typeof Symbol.toPrimitive === 'symbol') {
    badStringifier[Symbol.toPrimitive] = throwRegexMarker;
  }
}

var $toString = callBound('Object.prototype.toString');
var gOPD = Object.getOwnPropertyDescriptor;
var regexClass = '[object RegExp]';
module.exports = hasToStringTag // eslint-disable-next-line consistent-return
? function isRegex(value) {
  if (!value || typeof value !== 'object') {
    return false;
  }

  var descriptor = gOPD(value, 'lastIndex');
  var hasLastIndexDataProperty = descriptor && has(descriptor, 'value');

  if (!hasLastIndexDataProperty) {
    return false;
  }

  try {
    $exec(value, badStringifier);
  } catch (e) {
    return e === isRegexMarker;
  }
} : function isRegex(value) {
  // In older browsers, typeof regex incorrectly returns 'function'
  if (!value || typeof value !== 'object' && typeof value !== 'function') {
    return false;
  }

  return $toString(value) === regexClass;
};